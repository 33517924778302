<template>
    <section id="mainContent" class="w-screen h-screen pointer-events-none">
      <div id="contentHolder" class="absolute w-screen dkblue pointer-events-auto" v-show="!loggedIn">
        <div id="signup" class="lg:text-left md:text-center">
          <h1>You're logged in, welcome!</h1>
          <p>
            Explore our collection of inspirations, or revisit some of your favorites.
          </p>
          <div class="mx-auto text-center">
            <router-link class="btn bg-ltblue rounded-sm" to="/inspiration" tag="button">INSPIRATION</router-link>
            <router-link class="btn bg-ltblue rounded-sm" to="/favorites" tag="button">FAVORITES</router-link>
          </div>
        </div>
        <!-- <div id="welcome" v-show="loggedIn"></div> -->
      </div>
    </section>

</template>

<script>
import axios from 'axios';

export default {
  components: {},
  data() {
    return {
      loggedIn: false,
      favoritesList: [],
      affirmation: [],
      song: [],
      reading: [],
    };
  },
  mounted() {
    console.log('redirect params:');
    console.log(this.$route.query);
    this.$store.watch(
      (state) => {
        return state.user.authenticated;
      },
      ((newVal, oldVal) => {
        if (newVal !== oldVal) {
          //   this.$forceUpdate();
        }
      }).bind(this),
      {}
    );
  },
  watch: {},
  methods: {
    loginSubmitted() {


  //   fetch(`${backendUrl}/auth/${params.providerName}/callback${location.search}`)
  //     .then(res => {
  //       if (res.status !== 200) {
  //         throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
  //       }
  //       return res;
  //     })
  //     .then(res => res.json())
  //     .then(res => {
  //       // Successfully logged with Strapi
  //       // Now saving the jwt to use it for future authenticated requests to Strapi
  //       localStorage.setItem('jwt', res.jwt);
  //       localStorage.setItem('username', res.user.username);
  //       setText('You have been successfully logged in. You will be redirected in a few seconds...');
  //       setTimeout(() => history.push('/'), 3000); // Redirect to homepage after 3 sec
  //     })
  //     .catch(err => {
  //       console.log(err);
  //       setText('An error occurred, please see the developer console.')
  //     });
  // }, [history, location.search, params.providerName]);



      axios
        .get(`${this.$store.state.authURL}'cognito/callbacks${location.search}`)
        .then((response) => {
          console.log('Well done!');
          console.log('User profile', response.data.user);
          console.log('User token', response.data.jwt);
          this.$store.dispatch('setUserStatus', { target: 'authenticated', status: true });
          this.$store.dispatch('setUserStatus', { target: 'username', status: response.data.user });
          this.$store.dispatch('setUserStatus', { target: 'token', status: response.data.jwt });
        })
        .catch((error) => {
          // Handle error.
          console.log('An error occurred:', error.response);
        });
    },
  },
};
</script>
<style lang="scss"></style>
